import { keys, mapValues, pickBy } from 'lodash';
import { Network } from 'paraspace-configs-v2';
import { Environment } from 'parax-sdk';

import { ContractName } from '../types';

import { getContractsConfig } from './contractsConfig';
import { erc20Decimals } from './erc20DecimalsConfig';

import { ERC20Symbol, SupportedNetworks } from '@/apps/paraspace/typings';

export type TokenCategory = 'ETH' | 'Flooring';

export type ERC20TokenConfigInfo = {
  symbol: ERC20Symbol;
  contractName: ContractName;
  isNativeToken?: boolean;
  isNativeTokenOrDerivatives?: boolean;
  isWrappedNativeToken?: boolean;
  isFakeToken?: boolean;
  tokenCategory?: TokenCategory;
  nativeTokenSymbol?: string;
  // https://etherscan.io/address/0xdAC17F958D2ee523a2206206994597C13D831ec7#code#L199
  needResetAllowanceBeforeApprove?: boolean;
  displayName?: string; // display priority is higher than contractName
  apy?: number;
  decimals: number;
  address: string;
  disabled: boolean;
};

export type ERC20Config = Record<ERC20Symbol, ERC20TokenConfigInfo>;

const ERC20_CONFIG_FOR_SEPOLIA = {
  [ERC20Symbol.APE]: {
    symbol: ERC20Symbol.APE,
    decimals: erc20Decimals[ERC20Symbol.APE],
    contractName: 'APE'
  },
  [ERC20Symbol.CAPE]: {
    symbol: ERC20Symbol.CAPE,
    decimals: erc20Decimals[ERC20Symbol.CAPE],
    contractName: 'cAPE'
  },
  [ERC20Symbol.SAPE]: {
    symbol: ERC20Symbol.SAPE,
    decimals: erc20Decimals[ERC20Symbol.SAPE],
    isFakeToken: true,
    displayName: 'Staked Ape',
    contractName: 'sAPE' as ContractName
  },
  [ERC20Symbol.ETH]: {
    symbol: ERC20Symbol.ETH,
    decimals: erc20Decimals[ERC20Symbol.ETH],
    isNativeToken: true,
    contractName: '' as ContractName,
    isNativeTokenOrDerivatives: true,
    tokenCategory: 'ETH'
  },
  [ERC20Symbol.WETH]: {
    symbol: ERC20Symbol.WETH,
    decimals: erc20Decimals[ERC20Symbol.WETH],
    nativeTokenSymbol: 'ETH',
    contractName: 'WETH',
    isWrappedNativeToken: true,
    isNativeTokenOrDerivatives: true,
    tokenCategory: 'ETH'
  },
  [ERC20Symbol.STETH]: {
    symbol: ERC20Symbol.STETH,
    decimals: erc20Decimals[ERC20Symbol.STETH],
    contractName: 'stETH',
    apy: 0.047,
    isNativeTokenOrDerivatives: true,
    tokenCategory: 'ETH'
  },
  [ERC20Symbol.WSTETH]: {
    symbol: ERC20Symbol.WSTETH,
    decimals: erc20Decimals[ERC20Symbol.WSTETH],
    contractName: 'wstETH',
    apy: 0.047,
    isNativeTokenOrDerivatives: true,
    tokenCategory: 'ETH'
  },
  // [ERC20Symbol.CBETH]: {
  //   symbol: ERC20Symbol.CBETH,
  //   decimals: erc20Decimals[ERC20Symbol.CBETH],
  //   contractName: 'cbETH',
  //   apy: 0.0375,
  //   isNativeTokenOrDerivatives: true,
  //   tokenCategory: 'ETH'
  // },
  // [ERC20Symbol.RETH]: {
  //   symbol: ERC20Symbol.RETH,
  //   decimals: erc20Decimals[ERC20Symbol.RETH],
  //   contractName: 'rETH',
  //   apy: 0.0349,
  //   isNativeTokenOrDerivatives: true,
  //   tokenCategory: 'ETH'
  // },
  // [ERC20Symbol.AWETH]: {
  //   symbol: ERC20Symbol.AWETH,
  //   decimals: erc20Decimals[ERC20Symbol.AWETH],
  //   contractName: 'aWETH',
  //   apy: 0.0228,
  //   isNativeTokenOrDerivatives: true,
  //   tokenCategory: 'ETH'
  // },
  [ERC20Symbol.USDC]: {
    symbol: ERC20Symbol.USDC,
    decimals: erc20Decimals[ERC20Symbol.USDC],
    contractName: 'USDC'
  },
  [ERC20Symbol.USDT]: {
    symbol: ERC20Symbol.USDT,
    decimals: erc20Decimals[ERC20Symbol.USDT],
    contractName: 'USDT',
    needResetAllowanceBeforeApprove: true
  },
  [ERC20Symbol.DAI]: {
    symbol: ERC20Symbol.DAI,
    decimals: erc20Decimals[ERC20Symbol.DAI],
    contractName: 'DAI'
  },
  [ERC20Symbol.WBTC]: {
    symbol: ERC20Symbol.WBTC,
    decimals: erc20Decimals[ERC20Symbol.WBTC],
    contractName: 'WBTC'
  },
  [ERC20Symbol.BLUR]: {
    symbol: ERC20Symbol.BLUR,
    decimals: erc20Decimals[ERC20Symbol.BLUR],
    contractName: 'BLUR'
  },
  [ERC20Symbol.FRAX]: {
    symbol: ERC20Symbol.FRAX,
    decimals: erc20Decimals[ERC20Symbol.FRAX],
    contractName: 'FRAX'
  }
  // [ERC20Symbol.UBAYC]: {
  //   symbol: ERC20Symbol.UBAYC,
  //   decimals: erc20Decimals[ERC20Symbol.UBAYC],
  //   contractName: 'µBAYC',
  //   tokenCategory: 'Flooring'
  // },
  // [ERC20Symbol.UPPG]: {
  //   symbol: ERC20Symbol.UPPG,
  //   decimals: erc20Decimals[ERC20Symbol.UPPG],
  //   contractName: 'µPPG',
  //   tokenCategory: 'Flooring'
  // }
} as ERC20Config;

const ERC20_CONFIG_FOR_MAINNET = {
  [ERC20Symbol.APE]: {
    symbol: ERC20Symbol.APE,
    decimals: erc20Decimals[ERC20Symbol.APE],
    contractName: 'APE'
  },
  [ERC20Symbol.CAPE]: {
    symbol: ERC20Symbol.CAPE,
    decimals: erc20Decimals[ERC20Symbol.CAPE],
    contractName: 'cAPE'
  },
  [ERC20Symbol.SAPE]: {
    symbol: ERC20Symbol.SAPE,
    decimals: erc20Decimals[ERC20Symbol.SAPE],
    isFakeToken: true,
    displayName: 'Staked Ape',
    contractName: 'sAPE' as ContractName
  },
  [ERC20Symbol.ETH]: {
    symbol: ERC20Symbol.ETH,
    decimals: erc20Decimals[ERC20Symbol.ETH],
    isNativeToken: true,
    contractName: '' as ContractName,
    isNativeTokenOrDerivatives: true,
    tokenCategory: 'ETH'
  },
  [ERC20Symbol.WETH]: {
    symbol: ERC20Symbol.WETH,
    decimals: erc20Decimals[ERC20Symbol.WETH],
    nativeTokenSymbol: 'ETH',
    contractName: 'WETH',
    isWrappedNativeToken: true,
    isNativeTokenOrDerivatives: true,
    tokenCategory: 'ETH'
  },
  [ERC20Symbol.STETH]: {
    symbol: ERC20Symbol.STETH,
    decimals: erc20Decimals[ERC20Symbol.STETH],
    contractName: 'stETH',
    apy: 0.047,
    isNativeTokenOrDerivatives: true,
    tokenCategory: 'ETH'
  },
  [ERC20Symbol.WSTETH]: {
    symbol: ERC20Symbol.WSTETH,
    decimals: erc20Decimals[ERC20Symbol.WSTETH],
    contractName: 'wstETH',
    apy: 0.047,
    isNativeTokenOrDerivatives: true,
    tokenCategory: 'ETH'
  },
  [ERC20Symbol.CBETH]: {
    symbol: ERC20Symbol.CBETH,
    decimals: erc20Decimals[ERC20Symbol.CBETH],
    contractName: 'cbETH',
    apy: 0.0375,
    isNativeTokenOrDerivatives: true,
    tokenCategory: 'ETH'
  },
  [ERC20Symbol.RETH]: {
    symbol: ERC20Symbol.RETH,
    decimals: erc20Decimals[ERC20Symbol.RETH],
    contractName: 'rETH',
    apy: 0.0349,
    isNativeTokenOrDerivatives: true,
    tokenCategory: 'ETH'
  },
  [ERC20Symbol.AWETH]: {
    symbol: ERC20Symbol.AWETH,
    decimals: erc20Decimals[ERC20Symbol.AWETH],
    contractName: 'aWETH',
    apy: 0.0228,
    isNativeTokenOrDerivatives: true,
    tokenCategory: 'ETH'
  },
  [ERC20Symbol.USDC]: {
    symbol: ERC20Symbol.USDC,
    decimals: erc20Decimals[ERC20Symbol.USDC],
    contractName: 'USDC'
  },
  [ERC20Symbol.USDT]: {
    symbol: ERC20Symbol.USDT,
    decimals: erc20Decimals[ERC20Symbol.USDT],
    contractName: 'USDT',
    needResetAllowanceBeforeApprove: true
  },
  [ERC20Symbol.DAI]: {
    symbol: ERC20Symbol.DAI,
    decimals: erc20Decimals[ERC20Symbol.DAI],
    contractName: 'DAI'
  },
  [ERC20Symbol.WBTC]: {
    symbol: ERC20Symbol.WBTC,
    decimals: erc20Decimals[ERC20Symbol.WBTC],
    contractName: 'WBTC'
  },
  [ERC20Symbol.BLUR]: {
    symbol: ERC20Symbol.BLUR,
    decimals: erc20Decimals[ERC20Symbol.BLUR],
    contractName: 'BLUR'
  },
  [ERC20Symbol.FRAX]: {
    symbol: ERC20Symbol.FRAX,
    decimals: erc20Decimals[ERC20Symbol.FRAX],
    contractName: 'FRAX'
  },
  [ERC20Symbol.UBAYC]: {
    symbol: ERC20Symbol.UBAYC,
    decimals: erc20Decimals[ERC20Symbol.UBAYC],
    contractName: 'µBAYC',
    tokenCategory: 'Flooring'
  },
  [ERC20Symbol.UPPG]: {
    symbol: ERC20Symbol.UPPG,
    decimals: erc20Decimals[ERC20Symbol.UPPG],
    contractName: 'µPPG',
    tokenCategory: 'Flooring'
  }
} as ERC20Config;

const ERC20_CONFIG_FOR_ZKSYNC = {
  [ERC20Symbol.ETH]: {
    disabled: true,
    symbol: ERC20Symbol.ETH,
    decimals: erc20Decimals[ERC20Symbol.ETH],
    isNativeToken: true,
    contractName: '' as ContractName,
    isNativeTokenOrDerivatives: true,
    tokenCategory: 'ETH'
  },
  [ERC20Symbol.WETH]: {
    disabled: true,
    symbol: ERC20Symbol.WETH,
    decimals: erc20Decimals[ERC20Symbol.WETH],
    nativeTokenSymbol: 'ETH',
    contractName: 'WETH',
    isWrappedNativeToken: true,
    isNativeTokenOrDerivatives: true,
    tokenCategory: 'ETH'
  },
  [ERC20Symbol['USDC.E']]: {
    disabled: true,
    symbol: ERC20Symbol['USDC.E'],
    decimals: erc20Decimals[ERC20Symbol['USDC.E']],
    contractName: 'USDC',
    displayName: 'USDC.e'
  },
  [ERC20Symbol.WBTC]: {
    disabled: true,
    symbol: ERC20Symbol.WBTC,
    decimals: erc20Decimals[ERC20Symbol.WBTC],
    contractName: 'WBTC'
  }
} as ERC20Config;

const ERC20_CONFIG_FOR_ARBITRUM = {
  [ERC20Symbol.ETH]: {
    disabled: true,
    symbol: ERC20Symbol.ETH,
    decimals: erc20Decimals[ERC20Symbol.ETH],
    isNativeToken: true,
    contractName: '' as ContractName,
    isNativeTokenOrDerivatives: true,
    tokenCategory: 'ETH'
  },
  [ERC20Symbol.WETH]: {
    disabled: true,
    symbol: ERC20Symbol.WETH,
    decimals: erc20Decimals[ERC20Symbol.WETH],
    nativeTokenSymbol: 'ETH',
    contractName: 'WETH',
    isWrappedNativeToken: true,
    isNativeTokenOrDerivatives: true,
    tokenCategory: 'ETH'
  },
  [ERC20Symbol.WSTETH]: {
    disabled: true,
    symbol: ERC20Symbol.WSTETH,
    decimals: erc20Decimals[ERC20Symbol.WSTETH],
    contractName: 'wstETH',
    isNativeTokenOrDerivatives: true,
    tokenCategory: 'ETH'
  },
  [ERC20Symbol.USDC]: {
    disabled: true,
    symbol: ERC20Symbol.USDC,
    decimals: erc20Decimals[ERC20Symbol.USDC],
    contractName: 'USDC',
    displayName: 'USDC.e'
  },
  // [ERC20Symbol.USDT]: {
  //   disabled: true,
  //   symbol: ERC20Symbol.USDT,
  //   decimals: erc20Decimals[ERC20Symbol.USDT],
  //   contractName: 'USDT',
  //   needResetAllowanceBeforeApprove: true
  // },
  [ERC20Symbol.DAI]: {
    disabled: true,
    symbol: ERC20Symbol.DAI,
    decimals: erc20Decimals[ERC20Symbol.DAI],
    contractName: 'DAI'
  },
  [ERC20Symbol.WBTC]: {
    disabled: true,
    symbol: ERC20Symbol.WBTC,
    decimals: erc20Decimals[ERC20Symbol.WBTC],
    contractName: 'WBTC'
  },
  [ERC20Symbol.ARB]: {
    disabled: true,
    symbol: ERC20Symbol.ARB,
    decimals: erc20Decimals[ERC20Symbol.ARB],
    contractName: 'ARB'
  },
  [ERC20Symbol.GMX]: {
    disabled: true,
    symbol: ERC20Symbol.GMX,
    decimals: erc20Decimals[ERC20Symbol.GMX],
    contractName: 'GMX'
  },
  [ERC20Symbol.LINK]: {
    disabled: true,
    symbol: ERC20Symbol.LINK,
    decimals: erc20Decimals[ERC20Symbol.LINK],
    contractName: 'LINK'
  },
  [ERC20Symbol.UNI]: {
    disabled: true,
    symbol: ERC20Symbol.UNI,
    decimals: erc20Decimals[ERC20Symbol.UNI],
    contractName: 'UNI'
  },
  [ERC20Symbol.AAVE]: {
    disabled: true,
    symbol: ERC20Symbol.AAVE,
    decimals: erc20Decimals[ERC20Symbol.AAVE],
    contractName: 'AAVE'
  },
  [ERC20Symbol.RDNT]: {
    disabled: true,
    symbol: ERC20Symbol.RDNT,
    decimals: erc20Decimals[ERC20Symbol.RDNT],
    contractName: 'RDNT'
  }
} as ERC20Config;

const ERC20_CONFIG_FOR_PARALLEL_TESTNET = {
  [ERC20Symbol.ETH]: {
    symbol: ERC20Symbol.ETH,
    disabled: true,
    decimals: erc20Decimals[ERC20Symbol.ETH],
    isNativeToken: true,
    contractName: '' as ContractName,
    isNativeTokenOrDerivatives: true,
    tokenCategory: 'ETH'
  },
  [ERC20Symbol.WETH]: {
    symbol: ERC20Symbol.WETH,
    disabled: true,
    decimals: erc20Decimals[ERC20Symbol.WETH],
    nativeTokenSymbol: 'ETH',
    contractName: 'WETH',
    isWrappedNativeToken: true,
    isNativeTokenOrDerivatives: true,
    tokenCategory: 'ETH'
  },
  [ERC20Symbol.USDC]: {
    symbol: ERC20Symbol.USDC,
    disabled: true,
    decimals: erc20Decimals[ERC20Symbol.USDC],
    contractName: 'USDC'
  },
  [ERC20Symbol.USDT]: {
    symbol: ERC20Symbol.USDT,
    disabled: true,
    decimals: erc20Decimals[ERC20Symbol.USDT],
    contractName: 'USDT',
    needResetAllowanceBeforeApprove: true
  },
  [ERC20Symbol.DAI]: {
    symbol: ERC20Symbol.DAI,
    disabled: true,
    decimals: erc20Decimals[ERC20Symbol.DAI],
    contractName: 'DAI'
  },
  [ERC20Symbol.WBTC]: {
    symbol: ERC20Symbol.WBTC,
    decimals: erc20Decimals[ERC20Symbol.WBTC],
    contractName: 'WBTC'
  },
  [ERC20Symbol.FRAX]: {
    symbol: ERC20Symbol.FRAX,
    disabled: true,
    decimals: erc20Decimals[ERC20Symbol.FRAX],
    contractName: 'FRAX'
  }
} as ERC20Config;

const ERC20_CONFIG_FOR_NETWORK_MAP: Record<SupportedNetworks, ERC20Config> = {
  [Network.SEPOLIA]: ERC20_CONFIG_FOR_SEPOLIA,
  [Network.MAINNET]: ERC20_CONFIG_FOR_MAINNET,
  [Network.ZKSYNC_ERA]: ERC20_CONFIG_FOR_ZKSYNC,
  [Network.ZKSYNC_GOERLI]: ERC20_CONFIG_FOR_ZKSYNC,
  [Network.ARBITRUM_ONE]: ERC20_CONFIG_FOR_ARBITRUM,
  [Network.ARBITRUM_GOERLI]: ERC20_CONFIG_FOR_ARBITRUM,
  [Network.PARALLEL_L3_TESTNET]: ERC20_CONFIG_FOR_PARALLEL_TESTNET
};

export const getERC20Config = (chainId: SupportedNetworks, env: Environment) =>
  mapValues(ERC20_CONFIG_FOR_NETWORK_MAP[chainId], config => ({
    ...config,
    address: getContractsConfig(env).contracts[chainId][config.contractName]
  })) as ERC20Config;

export const getNativeTokenOrTokenDerivatives = (chainId: SupportedNetworks, env: Environment) =>
  keys(
    pickBy(getERC20Config(chainId, env), item => item.isNativeTokenOrDerivatives)
  ) as ERC20Symbol[];
