import styled from 'styled-components';
import { Button, H5, Inline } from '@parallel-mono/components';
import { useCallback } from 'react';

const BannerWrapper = styled(Inline).attrs({
  alignItems: 'center',
  justifyContent: 'center'
})`
  width: 100%;
  min-height: 3rem;
  align-self: center;
  background-color: #ffbc0e;
  margin-bottom: 0.5rem;
  ${({ theme }) => theme.breakpoints.down('lg')`
      width: 100%;
      height: auto;
      margin-top: 0;
  `};
`;

const StyledButton = styled(Button)`
  color: var(--clr-blue);
  text-decoration: underline;
`;

export const ImportNoticeBanner = () => {
  const handleClick = useCallback(() => {
    window.open(
      'https://parallelfinance.medium.com/important-announcement-product-closing-9eaa7fb63f55',
      '_blank'
    );
  }, []);

  return (
    <BannerWrapper gap=".75rem">
      <H5 fontWeight="medium">
        <StyledButton variant="link" onClick={handleClick}>
          Important Product Closure Notice
        </StyledButton>
      </H5>
    </BannerWrapper>
  );
};
