import styled from 'styled-components';
import { useMemo } from 'react';
import {
  Button,
  DataGrid,
  DataGridColumn,
  DataGridProps,
  H5,
  H6,
  Inline,
  SmallText,
  Stack,
  Text,
  useBreakpoints
} from '@parallel-mono/components';
import { CryptoIcon } from '@parallel-mono/business-components';
import { formatNumber, sumBy } from '@parallel-mono/utils';
import BigNumber from 'bignumber.js';
import { isEmpty, isNil } from 'lodash';

import { getEffectiveApy, handleCollapseRow } from '../utils';
import { SupplyERC20DropdownMenu, WalletsBalanceDropdown } from '../../components';
import { useEOABalances } from '../../../contexts';
import {
  DISABLE_BORROW_UTILIZATION_THRESHOLD,
  ESCAPE_UTILIZATION_THRESHOLD_USERS
} from '../../configs';

import { Handlers } from '.';

import { MAXIMUM_BALANCE_DECIMALS, MINIMUM_ACCOUNTABLE_NUM } from '@/apps/paraspace/pages/config';
import { useAppConfig } from '@/apps/paraspace/hooks';
import { ERC20Info, ERC20Symbol, FetchingStatus } from '@/apps/paraspace/typings';
import { useAutoCompoundApeInfo } from '@/apps/paraspace/pages/contexts/AutoCompoundApeProvider';
import {
  getEffectiveBorrowApyTip,
  getEffectiveSupplyApyTip
} from '@/apps/paraspace/pages/Credit/MoneyMarket/utils/getEffectiveApyTip';
import { ERC20TableRow } from '@/apps/paraspace/pages/Credit/MoneyMarket/ERC20Table/types';
import { StyledERC20TableDataGrid } from '@/apps/paraspace/pages/Credit/MoneyMarket/StyledComponents';
import { Maybe } from '@/apps/paraspace/typings/basic';
import { formatBalance, formatToCurrency, formatToPercentage } from '@/apps/paraspace/utils/format';
import { ERC20Config } from '@/apps/paraspace/config';
import { zero } from '@/apps/paraspace/consts/values';
import { Tooltip } from '@/apps/paraspace/components';
import { useWeb3Context } from '@/apps/paraspace/contexts';

export const InvisibleButton = styled(Button)<{ visible: boolean }>`
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
`;

export const BalanceWrapper = styled(Stack)`
  min-width: 5rem;
`;

const StyledH5 = styled(H5)`
  ${({ theme }) => theme.breakpoints.not('desktop')`
    white-space: nowrap;
  `}
`;

type TokensTableProps = {
  handlers: Handlers;
  tokens: ERC20TableRow[];
};

const createColumns = ({
  effectiveCapeBorrowApy,
  effectiveCapeSupplyApy,
  handlers,
  isMobile,
  isTablet,
  erc20Config,
  nativeTokenAndDerivatives,
  EOABalances,
  account
}: {
  effectiveCapeBorrowApy: Maybe<BigNumber>;
  effectiveCapeSupplyApy: Maybe<BigNumber>;
  handlers: Handlers;
  isMobile: boolean;
  isTablet: boolean;
  account: string;
  erc20Config: ERC20Config;
  nativeTokenAndDerivatives: ERC20Symbol[];
  EOABalances: { loading: boolean; balances: Maybe<Record<ERC20Symbol, BigNumber>> };
}): DataGridColumn<ERC20TableRow>[] => [
  {
    name: 'assets',
    title: 'Assets',
    width: '1fr',
    render: ({ data: { displayName, symbol, displayIcon } }) => (
      <Inline gap="1rem" alignItems="center">
        {/* for erc20 table we display ETH but use WETH data in the pool */}
        {!isTablet && (displayIcon || <CryptoIcon symbol={symbol} />)}
        {!isTablet && <H5 fontWeight="bold">{displayName}</H5>}
        {isTablet && (
          <Stack gap="0" alignItems="center">
            {displayIcon || <CryptoIcon symbol={symbol} />}
            <StyledH5 fontWeight="bold">{displayName}</StyledH5>
          </Stack>
        )}
      </Inline>
    )
  },
  {
    name: 'balance',
    title: 'Your Balance',
    width: '1fr',
    hidden: isMobile,
    render: ({ data: { balance: paraXBalance = zero, priceInUsd, symbol, subRows } }) => {
      const EOABalance = EOABalances.balances?.[symbol] ?? zero;
      const totalEOABalance = isEmpty(subRows)
        ? EOABalance
        : sumBy(subRows ?? [], item => EOABalances.balances?.[item.symbol] ?? 0);

      const totalBalance = totalEOABalance.plus(paraXBalance);
      if (totalBalance?.eq(0)) {
        return <Text>-</Text>;
      }
      return (
        <Stack gap="0">
          <Text>
            {formatNumber(totalBalance, {
              decimal: MAXIMUM_BALANCE_DECIMALS,
              threshold: {
                min: MINIMUM_ACCOUNTABLE_NUM
              }
            })}
          </Text>
          <WalletsBalanceDropdown
            paraXBalance={{
              loading: false,
              value: (
                <BalanceWrapper alignItems="flex-end" gap="0">
                  <H6>
                    {formatNumber(paraXBalance, {
                      decimal: MAXIMUM_BALANCE_DECIMALS,
                      threshold: {
                        min: MINIMUM_ACCOUNTABLE_NUM
                      }
                    })}
                  </H6>
                  <SmallText skin="secondary">
                    {formatToCurrency(paraXBalance.times(priceInUsd))}
                  </SmallText>
                </BalanceWrapper>
              )
            }}
            EOABalance={{
              loading: EOABalances.loading,
              value: (
                <BalanceWrapper alignItems="flex-end" gap="0">
                  <H6>
                    {formatNumber(totalEOABalance, {
                      decimal: MAXIMUM_BALANCE_DECIMALS,
                      threshold: {
                        min: MINIMUM_ACCOUNTABLE_NUM
                      }
                    })}
                  </H6>
                  <SmallText skin="secondary">
                    {formatToCurrency(priceInUsd.times(totalEOABalance))}
                  </SmallText>
                </BalanceWrapper>
              )
            }}
          />
        </Stack>
      );
    }
  },
  {
    name: 'supply',
    title: 'Supply APY',
    width: '1fr',
    hidden: isMobile,
    render: ({ data }) => {
      const { supplyApyRate, symbol, subRows } = data;
      const isNativeTokenOrDerivatives = nativeTokenAndDerivatives.includes(symbol);
      const effectiveSupplyApyTooltip = getEffectiveSupplyApyTip({
        symbol,
        baseApy: supplyApyRate,
        apy: erc20Config[symbol]?.apy ?? null,
        isNativeTokenOrDerivatives
      });
      const effectiveSupplyApy = getEffectiveApy({
        baseApy: supplyApyRate,
        effectiveCapeApy: effectiveCapeSupplyApy,
        symbol,
        apy: erc20Config[symbol]?.apy,
        isNativeTokenOrDerivatives
      });
      return (
        <Stack gap="0" alignItems={isMobile ? 'flex-end' : 'flex-start'}>
          <Inline alignItems="center" gap=".25rem">
            <Text>
              {!isEmpty(subRows) && '~'}
              {effectiveSupplyApy}
            </Text>
            {effectiveSupplyApyTooltip}
          </Inline>
        </Stack>
      );
    }
  },
  {
    name: 'borrow',
    title: 'Borrow APY',
    width: '1fr',
    hidden: isMobile,
    render: ({ data: { borrowApyRate, borrowingEnabled, symbol, subRows } }) => {
      if (borrowingEnabled) {
        const isNativeTokenOrDerivatives = nativeTokenAndDerivatives.includes(symbol);
        const effectiveBorrowApyTooltip = getEffectiveBorrowApyTip({
          symbol,
          baseApy: borrowApyRate,
          apy: erc20Config[symbol]?.apy ?? null,
          isNativeTokenOrDerivatives
        });
        const effectiveBorrowApy = getEffectiveApy({
          baseApy: borrowApyRate,
          effectiveCapeApy: effectiveCapeBorrowApy,
          symbol,
          apy: erc20Config[symbol]?.apy,
          isNativeTokenOrDerivatives
        });
        return (
          <Stack gap="0">
            <Inline alignItems="center" gap=".25rem">
              <Text>
                {!isEmpty(subRows) && '~'}
                {effectiveBorrowApy}
              </Text>
              {effectiveBorrowApyTooltip}
            </Inline>
          </Stack>
        );
      }
      return <Text>-</Text>;
    }
  },
  {
    name: 'totalSupply',
    title: 'Total Supply',
    width: '1fr',
    hidden: isMobile,
    render: ({ data: { totalDebt, availableLiquidity } }) => {
      const utilization = totalDebt.div(totalDebt.plus(availableLiquidity));
      return (
        <Stack gap="0">
          <Text>{formatBalance(totalDebt.plus(availableLiquidity))}</Text>
          <SmallText skin="secondary">
            {utilization.isNaN() ? '-' : formatToPercentage(utilization)}
            &nbsp;utilized
          </SmallText>
        </Stack>
      );
    }
  },
  {
    title: '',
    name: 'actions',
    width: 'auto',
    render: ({
      data,
      data: { totalDebt, availableLiquidity, symbol, borrowingEnabled, tokenCategory }
    }) => {
      const utilization = totalDebt.div(totalDebt.plus(availableLiquidity));
      const { disabled } = erc20Config[symbol];
      return (
        <Inline gap="1rem" alignItems="center">
          {disabled ? (
            <Button skin="secondary" disabled={disabled}>
              Supply
            </Button>
          ) : (
            <SupplyERC20DropdownMenu
              symbol={symbol as ERC20Symbol}
              menuTrigger={<Button skin="secondary">Supply</Button>}
              eoaWalletOption={{
                onClick: () => handlers.handleSupplyERC20FromEOA(symbol as ERC20Symbol)
              }}
              aaWalletOption={{
                onClick: () => handlers.handleSupplyERC20FromAA(symbol as ERC20Symbol)
              }}
            />
          )}
          <Tooltip
            disabled={
              utilization.lt(DISABLE_BORROW_UTILIZATION_THRESHOLD) ||
              ESCAPE_UTILIZATION_THRESHOLD_USERS.includes(account)
            }
            content="The current utilization rate is too high. Please try again later."
          >
            <InvisibleButton
              visible={borrowingEnabled}
              disabled={
                disabled ||
                !borrowingEnabled ||
                (utilization.gte(DISABLE_BORROW_UTILIZATION_THRESHOLD) &&
                  !ESCAPE_UTILIZATION_THRESHOLD_USERS.includes(account))
              }
              size={isMobile ? 'small' : 'medium'}
              skin="secondary"
              onClick={() => {
                if (nativeTokenAndDerivatives.includes(symbol) || !isNil(tokenCategory)) {
                  handlers.handleBorrowNativeTokenDerivatives(symbol);
                } else {
                  handlers.handleBorrow(data);
                }
              }}
            >
              Borrow
            </InvisibleButton>
          </Tooltip>
        </Inline>
      );
    }
  }
];

const TokensTable = ({ handlers, tokens }: TokensTableProps) => {
  const { mobile, tablet } = useBreakpoints();
  const { effectiveCapeSupplyApy, effectiveCapeBorrowApy } = useAutoCompoundApeInfo();
  const { erc20BalanceMap, erc20BalanceMapPollingStatus } = useEOABalances();
  const { account } = useWeb3Context();
  const isERC20BalanceLoading =
    erc20BalanceMap === null && erc20BalanceMapPollingStatus === FetchingStatus.FETCHING;

  const { erc20Config, nativeTokenAndDerivatives } = useAppConfig();

  const columns = useMemo(
    () =>
      createColumns({
        effectiveCapeBorrowApy,
        effectiveCapeSupplyApy,
        handlers,
        isMobile: mobile,
        isTablet: tablet,
        EOABalances: {
          loading: isERC20BalanceLoading,
          balances: erc20BalanceMap
        },
        erc20Config,
        nativeTokenAndDerivatives,
        account
      }),
    [
      effectiveCapeBorrowApy,
      effectiveCapeSupplyApy,
      handlers,
      mobile,
      tablet,
      isERC20BalanceLoading,
      erc20BalanceMap,
      erc20Config,
      nativeTokenAndDerivatives,
      account
    ]
  );

  const expandableMeta: DataGridProps<ERC20TableRow>['expandable'] = useMemo(
    () => ({
      rowExpandable: ({ rowExpandable }) => !!rowExpandable,
      renderExpandable: rowData => {
        return (
          <DataGrid<ERC20Info>
            columns={columns as unknown as DataGridColumn<ERC20Info>[]}
            data={rowData.data?.subRows ?? []}
            hideColumnsTitle
          />
        );
      }
    }),
    [columns]
  );

  return (
    <StyledERC20TableDataGrid
      expandable={expandableMeta}
      hideColumnsTitle={mobile}
      title="ERC-20 Tokens"
      columns={columns}
      data={tokens}
      onRowClick={handleCollapseRow}
    />
  );
};

export default TokensTable;
