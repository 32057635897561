import { Stack } from '@parallel-mono/components';
import { memo, useEffect, useMemo } from 'react';
import { Navigate, Route, Routes, useMatch } from 'react-router-dom';
import styled from 'styled-components';

import { Header } from '../components';
import { SUPPORTED_NETWORKS, getChainKey, getNetworkByChainPath } from '../config';
import { AppsContextProvider, useEOAProvider } from '../contexts';

import { KeepMountedAppsPage } from './KeepMountedAppsPage';
import { LegacyAppsRouteSupport } from './LegacyAppsRouteSupport';
import './app.css';
import { NavigateToDefaultApp } from './NavigateToDefaultApp';
import { useScaffoldContainerRef } from './Scaffold';

import { ImportNoticeBanner } from '@/apps/paraspace/components/ImportantNoticeBanner';

const Body = styled.div`
  flex: 0 0 100%;
`;

export const Apps = memo(() => {
  const { chainId, provider } = useEOAProvider();
  const currentChainPath = getChainKey(chainId);

  const match = useMatch(':chainName/apps/*');
  const chainIdFromUrl = useMemo(
    () => getNetworkByChainPath(match?.params.chainName ?? ''),
    [match]
  );
  useEffect(() => {
    if (!provider) {
      return undefined;
    }
    const handleChainChanged = ({ chainId: latestChainId }: { chainId: number }) => {
      if (chainIdFromUrl !== latestChainId && SUPPORTED_NETWORKS.includes(latestChainId)) {
        window.open('/', '_self');
      }
    };
    provider.on('network', handleChainChanged);

    return () => {
      provider.off('network', handleChainChanged);
    };
  }, [provider, chainIdFromUrl]);

  const scaffoldContainerRef = useScaffoldContainerRef();

  return (
    <Stack gap="0">
      <AppsContextProvider>
        <Header bodyContainer={scaffoldContainerRef} />
        <ImportNoticeBanner />
        <Body>
          <KeepMountedAppsPage />
          <Routes>
            <Route path="/apps/*" element={<LegacyAppsRouteSupport />} />
            <Route path="/" element={<Navigate to={`./${currentChainPath}`} replace />} />
            <Route path="/:chainName" element={<Navigate to="./apps" replace />} />
            <Route path=":chainName/apps" element={<NavigateToDefaultApp />} />
          </Routes>
        </Body>
      </AppsContextProvider>
    </Stack>
  );
});
